import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Drawer, Flex, Form, Input, Row, Select, Space, notification } from 'antd';

import { logoutAuthSuccess } from '~/redux/reducer/auth';
import { requestCreatePartner } from '~/services/partner';

interface CreatePartnerProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    callback: object[];
    setCallback: React.Dispatch<React.SetStateAction<any>>;
}

function CreatePartner({ open, setOpen, callback, setCallback }: CreatePartnerProps) {
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleCreatePartner = async (data: object) => {
        const result = await requestCreatePartner(data);

        if (result.status === 401 || result.status === 403) {
            navigate('/');
            dispatch(logoutAuthSuccess());
        } else if (result?.status === 200) {
            setCallback([result.data, ...callback]);

            form.resetFields();
            setOpen(false);
            notification.success({
                message: 'Thông báo',
                description: result.message,
            });
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    return (
        <Drawer
            title="Tạo mới đối tác"
            width={720}
            onClose={() => setOpen(false)}
            open={open}
            styles={{
                body: {
                    paddingBottom: 80,
                },
            }}
        >
            <Form layout="vertical" form={form} onFinish={handleCreatePartner} initialValues={{ partner_key: '', balance: 0 }}>
                <Row gutter={16}>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="bank_name"
                            label="Chọn ngân hàng"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn ngân hàng',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Chọn ngân hàng"
                                options={[
                                    { label: 'MoMo', value: 'MoMo' },
                                    { label: 'MB Bank', value: 'MB Bank' },
                                    { label: 'VietinBank iPay', value: 'VietinBank iPay' },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="interbank_code"
                            label="Mã liên ngân hàng"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập mã liên ngân hàng',
                                },
                            ]}
                        >
                            <Input placeholder="Mã liên ngân hàng" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="account_number"
                            label="Số tài khoản"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập số tài khoản',
                                },
                            ]}
                        >
                            <Input placeholder="Số tài khoản" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="account_holder"
                            label="Chủ tài khoản"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập chủ tài khoản',
                                },
                            ]}
                        >
                            <Input placeholder="Chủ tài khoản" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item name="balance" label="Số dư">
                            <Input placeholder="Số dư" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="partner_name"
                            label="Tên đối tác"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập tên đối tác',
                                },
                            ]}
                        >
                            <Input placeholder="Tên đối tác" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="partner_url"
                            label="Callback url"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập callback url',
                                },
                            ]}
                        >
                            <Input placeholder="Callback url" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="partner_key"
                            label="Key bảo mật"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập key bảo mật',
                                },
                            ]}
                        >
                            <Input placeholder="Key bảo mật" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="paygate_code"
                            label="Mã cổng thanh toán"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập mã cổng thanh toán',
                                },
                            ]}
                        >
                            <Input placeholder="Mã cổng thanh toán" />
                        </Form.Item>
                    </Col>
                </Row>

                <Flex justify="end" style={{ marginTop: 22 }}>
                    <Space>
                        <Button onClick={() => setOpen(false)}>Huỷ</Button>
                        <Button type="primary" htmlType="submit">
                            Tạo đối tác
                        </Button>
                    </Space>
                </Flex>
            </Form>
        </Drawer>
    );
}

export default CreatePartner;
