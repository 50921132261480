import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Drawer, Flex, Form, Input, Row, Select, Space, notification } from 'antd';

import { logoutAuthSuccess } from '~/redux/reducer/auth';
import { requestUpdatePartner } from '~/services/partner';

interface PartnerProps {
    key: string;
    bank_name: string;
    account_number: string;
    account_holder: string;
    partner_name: string;
    partner_key: string;
    partner_url: string;
    balance: number;
    paygate_code: string;
    interbank_code: string;
}

interface UpdatePartnerProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    callback: object[];
    setCallback: React.Dispatch<React.SetStateAction<any>>;
    partner: PartnerProps;
}

function UpdatePartner({ open, setOpen, callback, setCallback, partner }: UpdatePartnerProps) {
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleUpdatePartner = async (data: object) => {
        const result = await requestUpdatePartner(partner.key, 'info', data);

        if (result.status === 401 || result.status === 403) {
            navigate('/');
            dispatch(logoutAuthSuccess());
        } else if (result?.status === 200) {
            const clonePartners = [...callback];
            const indexPartner = clonePartners.findIndex((p: any) => p.key === partner.key);

            if (indexPartner !== -1) {
                clonePartners[indexPartner] = result.data;
                setCallback(clonePartners);
                setOpen(false);
                notification.success({
                    message: 'Thông báo',
                    description: result.message,
                });
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: 'Không tìm thấy đối tác trong danh sách',
                });
            }
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    return (
        <Drawer
            title="Chi tiết đối tác"
            width={720}
            onClose={() => setOpen(false)}
            open={open}
            styles={{
                body: {
                    paddingBottom: 80,
                },
            }}
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={handleUpdatePartner}
                initialValues={{
                    balance: partner.balance,
                    bank_name: partner.bank_name,
                    partner_url: partner.partner_url,
                    partner_key: partner.partner_key,
                    paygate_code: partner.paygate_code,
                    partner_name: partner.partner_name,
                    interbank_code: partner.interbank_code,
                    account_number: partner.account_number,
                    account_holder: partner.account_holder,
                }}
            >
                <Row gutter={16}>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="bank_name"
                            label="Chọn ngân hàng"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn ngân hàng',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Chọn ngân hàng"
                                options={[
                                    { label: 'MoMo', value: 'MoMo' },
                                    { label: 'MB Bank', value: 'MB Bank' },
                                    { label: 'VietinBank iPay', value: 'VietinBank iPay' },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="interbank_code"
                            label="Mã liên ngân hàng"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập mã liên ngân hàng',
                                },
                            ]}
                        >
                            <Input placeholder="Mã liên ngân hàng" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="account_number"
                            label="Số tài khoản"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập số tài khoản',
                                },
                            ]}
                        >
                            <Input placeholder="Số tài khoản" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item name="balance" label="Số dư">
                            <Input placeholder="Số dư" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="account_holder"
                            label="Chủ tài khoản"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập chủ tài khoản',
                                },
                            ]}
                        >
                            <Input placeholder="Chủ tài khoản" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="partner_name"
                            label="Tên đối tác"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập tên đối tác',
                                },
                            ]}
                        >
                            <Input placeholder="Tên đối tác" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="partner_url"
                            label="Callback url"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập callback url',
                                },
                            ]}
                        >
                            <Input placeholder="Callback url" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="partner_key"
                            label="Key bảo mật"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập key bảo mật',
                                },
                            ]}
                        >
                            <Input placeholder="Key bảo mật" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="paygate_code"
                            label="Mã cổng thanh toán"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập mã cổng thanh toán',
                                },
                            ]}
                        >
                            <Input placeholder="Mã cổng thanh toán" />
                        </Form.Item>
                    </Col>
                </Row>

                <Flex justify="end" style={{ marginTop: 22 }}>
                    <Space>
                        <Button onClick={() => setOpen(false)}>Huỷ</Button>
                        <Button type="primary" htmlType="submit">
                            Cập nhật
                        </Button>
                    </Space>
                </Flex>
            </Form>
        </Drawer>
    );
}

export default UpdatePartner;
