import dayjs from 'dayjs';
import moment, { Moment } from 'moment';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Fragment } from 'react/jsx-runtime';
import vi from 'antd/es/date-picker/locale/vi_VN';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { IconArrowLeft, IconInfoCircleFilled, IconSearch, IconTrash, IconWebhook } from '@tabler/icons-react';
import {
    Card,
    Flex,
    Form,
    Spin,
    Input,
    Space,
    Table,
    Button,
    Select,
    Tooltip,
    DatePicker,
    Breadcrumb,
    Pagination,
    Popconfirm,
    notification,
} from 'antd';

import { convertCurrency } from '~/configs';
import IconQuestion from '~/assets/icon/IconQuestion';
import { logoutAuthSuccess } from '~/redux/reducer/auth';
import ModelTransaction from '~/components/ModelTransaction';
import { requestDestroyCallback, requestGetCallbacks } from '~/services/statistic';

dayjs.extend(customParseFormat);

const buddhistLocale = {
    ...vi,
    lang: {
        ...vi.lang,
        fieldDateFormat: 'YYYY-MM-DD',
    },
};

const { RangePicker } = DatePicker;

interface SearchCallbacks {
    status?: string;
    date_start?: string;
    date_end?: string;
    date?: Moment[];
    page: number | string;
    trans_id?: number | string;
    partner_id?: number | string;
    order_id?: number | string;
}

const convertDate = (date_start: string, date_end: string) => {
    if (date_start && date_end) {
        return [dayjs(date_start), dayjs(date_end)];
    }

    return [];
};

function Callbacks() {
    const [callback, setCallback] = useState<object>({});
    const [response, setResponse] = useState<object>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [pages, setPages] = useState<number | string>(1);
    const [callbacks, setCallbacks] = useState<object[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [description, setDescription] = useState<object>({});
    const [openCallback, setOpenCallback] = useState<boolean>(false);
    const [openResponse, setOpenResponse] = useState<boolean>(false);
    const [openDescription, setOpenDescription] = useState<boolean>(false);
    const [page, setPage] = useState<number | string>(searchParams.get('page') || 1);

    const status = searchParams.get('status') || '';
    const date_end = searchParams.get('date_end') || '';
    const date_start = searchParams.get('date_start') || '';

    const date = convertDate(date_start, date_end);

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = 'Ha2cm.com - Thống kê callback';

        setLoading(true);
        const fetch = async () => {
            let objectSearch: SearchCallbacks = { page: page || 1 };
            if (status) {
                objectSearch.status = status;
            }
            if (date_start) {
                objectSearch.date_start = date_start;
            }
            if (date_end) {
                objectSearch.date_end = date_end;
            }

            const result = await requestGetCallbacks(objectSearch);

            if (result.status === 401 || result.status === 403) {
                navigate('/login');
                dispatch(logoutAuthSuccess());
            } else if (result.status === 200) {
                setPages(result.pages);
                setCallbacks(result.data);
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
                });
            }

            setLoading(false);
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    const handleSearchCallback = async (values: SearchCallbacks) => {
        const { date, status, trans_id, order_id, partner_id } = values;

        let objectSearch: SearchCallbacks = { page: page || 1 };
        let searchParams: SearchCallbacks = { page: page || 1 };
        if (date && date?.length > 0) {
            objectSearch.date_start = date[0].format('YYYY-MM-DD');
            objectSearch.date_end = date[1].format('YYYY-MM-DD');
            searchParams.date_start = date[0].format('YYYY-MM-DD');
            searchParams.date_end = date[1].format('YYYY-MM-DD');
        }
        if (status) {
            objectSearch.status = status;
            searchParams.status = status;
        }
        if (trans_id) {
            objectSearch.trans_id = trans_id;
        }
        if (order_id) {
            objectSearch.order_id = order_id;
        }
        if (partner_id) {
            objectSearch.partner_id = partner_id;
        }

        // Chuyển đổi tất cả các giá trị trong objectSearch thành chuỗi
        const newSearchParams = new URLSearchParams(
            Object.entries(searchParams).reduce((acc, [key, value]) => {
                if (value !== undefined && value !== null) {
                    acc[key] = String(value);
                }
                return acc;
            }, {} as Record<string, string>),
        );

        // Cập nhật search params
        setSearchParams(newSearchParams);

        const result = await requestGetCallbacks(objectSearch);

        if (result.status === 401 || result.status === 403) {
            navigate('/login');
            dispatch(logoutAuthSuccess());
        } else if (result.status === 200) {
            setPages(result.pages);
            setCallbacks(result.data);
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    const confirmDestroyCallback = async (id: string) => {
        if (!id) {
            return notification.error({
                message: 'Thông báo',
                description: 'Không lấy được ID đơn hàng cần xoá',
            });
        }

        const result = await requestDestroyCallback(id);

        if (result.status === 200) {
            const cloneCallbacks = [...callbacks];

            const indexCallback = cloneCallbacks.findIndex((item: any) => item.key === id);
            if (indexCallback !== -1) {
                cloneCallbacks.splice(indexCallback, 1);
                setCallbacks(cloneCallbacks);

                notification.error({
                    message: 'Thông báo',
                    description: result.message,
                });
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: 'Không tìm thấy ID giao dịch trong danh sách',
                });
            }
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'order_id',
            key: 'order_id',
            render: (order_id: number) => <Fragment>#{order_id}</Fragment>,
        },
        {
            title: 'ID đối tác',
            dataIndex: 'partner_id',
            key: 'partner_id',
            render: (partner_id: number) => <Fragment>#{partner_id}</Fragment>,
        },
        {
            title: 'ID giao dịch',
            dataIndex: 'trans_id',
            key: 'trans_id',
            render: (trans_id: number) => <Fragment>#{trans_id}</Fragment>,
        },
        {
            title: 'Ngân hàng',
            dataIndex: 'bank_name',
            key: 'bank_name',
        },
        {
            title: 'Thông tin',
            key: 'account',
            render: (account: any) => (
                <Fragment>
                    <span>{account.account_number}</span>
                    <br />
                    <span>{account.account_holder}</span>
                </Fragment>
            ),
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: number) => {
                let className = '';
                let title = '';
                if (amount >= 0) {
                    title = `+${convertCurrency(amount)}`;
                    className = 'text-success';
                } else {
                    title = convertCurrency(amount);
                    className = 'text-danger';
                }

                return <span className={className}>{title}</span>;
            },
        },
        {
            title: 'Số dư cuối',
            dataIndex: 'balance',
            key: 'balance',
            render: (balance: number) => <Fragment>{convertCurrency(balance)}</Fragment>,
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => {
                let className = '';
                let style = {};
                let title = '';

                if (status === 'completed') {
                    title = 'Hoàn thành';
                    className = 'label-light-success font-weight-bold';
                    style = { backgroundColor: '#4caf501a', color: '#4caf50', border: '1px solid #4caf501a' };
                }
                if (status === 'pending') {
                    title = 'Đang chờ';
                    className = 'label-light-warning font-weight-bold';
                    style = { backgroundColor: '#ff98001a', color: '#ff9800', border: '1px solid #ff98001a' };
                }
                if (status === 'canceled') {
                    title = 'Đã hủy';
                    className = 'label-light-danger font-weight-bold';
                    style = { backgroundColor: '#f443361a', color: '#f44336', border: '1px solid #f443361a' };
                }

                return (
                    <div className={className} style={style}>
                        {title}
                    </div>
                );
            },
        },
        {
            title: 'Callback',
            dataIndex: 'callbacked',
            key: 'callbacked',
            render: (callbacked: string) => {
                let className = '';
                let style = {};
                let title = '';

                if (callbacked === 'completed') {
                    title = 'Thành công';
                    className = 'label-light-success font-weight-bold';
                    style = { backgroundColor: '#4caf501a', color: '#4caf50', border: '1px solid #4caf501a' };
                }
                if (callbacked === 'pending') {
                    title = 'Đang chờ';
                    className = 'label-light-warning font-weight-bold';
                    style = { backgroundColor: '#ff98001a', color: '#ff9800', border: '1px solid #ff98001a' };
                }
                if (callbacked === 'failed') {
                    title = 'Thất bại';
                    className = 'label-light-danger font-weight-bold';
                    style = { backgroundColor: '#f443361a', color: '#f44336', border: '1px solid #f443361a' };
                }

                return (
                    <div className={className} style={style}>
                        {title}
                    </div>
                );
            },
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at: string) => <span>{moment(created_at).format('YYYY-MM-DD HH:mm:ss')}</span>,
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (data: any) => (
                <Flex align="center" gap={10}>
                    <Tooltip title="Xem nội dung">
                        <Button
                            type="primary"
                            size="small"
                            className="box-center"
                            onClick={() => {
                                setDescription(data.description);
                                setOpenDescription(true);
                            }}
                        >
                            <IconInfoCircleFilled size={18} />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Xem callback">
                        <Button
                            type="primary"
                            size="small"
                            className="box-center"
                            onClick={() => {
                                setCallback(data.callback);
                                setOpenCallback(true);
                            }}
                        >
                            <IconWebhook size={18} />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Xem response">
                        <Button
                            type="primary"
                            size="small"
                            className="box-center"
                            onClick={() => {
                                setResponse(data.response);
                                setOpenResponse(true);
                            }}
                        >
                            <IconWebhook size={18} />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Xoá">
                        <Popconfirm
                            title="Delete?"
                            description={`ID #${data.order_id}`}
                            onConfirm={() => confirmDestroyCallback(data.key)}
                            okText="Xoá"
                            cancelText="Huỷ"
                            icon={<IconQuestion width={14} height={14} className="mt-1 mr-1" style={{ color: '#ff4d4f' }} />}
                        >
                            <Button danger type="primary" size="small" className="box-center">
                                <IconTrash size={18} />
                            </Button>
                        </Popconfirm>
                    </Tooltip>
                </Flex>
            ),
        },
    ];

    return (
        <Space style={{ width: '100%', flexDirection: 'column' }}>
            <Card styles={{ body: { padding: 12 } }}>
                <Flex justify="space-between" align="center" className="responsive-flex">
                    <Flex className="gap-2 responsive-item" align="center">
                        <Button size="small" className="box-center" onClick={() => navigate('/')}>
                            <IconArrowLeft size={18} />
                        </Button>
                        <Breadcrumb
                            items={[
                                {
                                    title: <Link to="/">Trang chủ</Link>,
                                },

                                {
                                    title: 'Thống kê callback',
                                },
                            ]}
                        />
                    </Flex>
                    <div className="responsive-item"></div>
                    <Form layout="vertical" form={form} onFinish={handleSearchCallback} initialValues={{ status, date }}>
                        <Flex justify="end" className="responsive-flex">
                            <Form.Item className="mb-0 responsive-item" name="status">
                                <Select
                                    style={{ width: 140 }}
                                    placeholder="Trạng thái"
                                    className="ml-2 responsive-child"
                                    options={[
                                        { label: 'Trạng thái', value: '' },
                                        { label: 'Thất bại', value: 'failed' },
                                        { label: 'Đang chờ', value: 'pending' },
                                        { label: 'Hoàn thành', value: 'completed' },
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item name="date" className="mb-0 responsive-item">
                                <RangePicker locale={buddhistLocale} className="ml-3 responsive-child" />
                            </Form.Item>
                            <Form.Item className="mb-0 responsive-item" name="order_id">
                                <Input placeholder="ID đơn" className="ml-2 responsive-child" style={{ width: 180 }} />
                            </Form.Item>
                            <Form.Item className="mb-0 responsive-item" name="partner_id">
                                <Input placeholder="ID đối tác" className="ml-2 responsive-child" style={{ width: 180 }} />
                            </Form.Item>
                            <Form.Item className="mb-0 responsive-item" name="trans_id">
                                <Input placeholder="ID giao dịch" className="ml-2 responsive-child" style={{ width: 180 }} />
                            </Form.Item>
                            <Tooltip title="Tìm kiếm">
                                <Button
                                    type="primary"
                                    className="box-center ml-3 bg-warning xs-mt-3"
                                    htmlType="submit"
                                    style={{ padding: '4px 8px' }}
                                >
                                    <IconSearch size={20} />
                                </Button>
                            </Tooltip>
                        </Flex>
                    </Form>
                </Flex>
            </Card>

            {openCallback && <ModelTransaction open={openCallback} onCancel={setOpenCallback} callback={callback} title="Callback" />}
            {openResponse && (
                <ModelTransaction open={openResponse} onCancel={setOpenResponse} callback={response} title="Response callback" />
            )}
            {openDescription && (
                <ModelTransaction open={openDescription} onCancel={setOpenDescription} callback={description} title="Nội dung giao dịch" />
            )}

            <Card style={{ minHeight: 'calc(-171px + 100vh)' }}>
                {!loading ? (
                    <Table columns={columns} dataSource={callbacks} pagination={false} />
                ) : (
                    <Flex align="center" justify="center" style={{ minHeight: '60vh' }}>
                        <Spin />
                    </Flex>
                )}

                {Number(pages) > 1 && (
                    <Flex justify="end" style={{ margin: '20px 0 10px 0' }}>
                        <Pagination
                            current={Number(page) || 1}
                            pageSize={20}
                            total={Number(pages) * 20}
                            onChange={(page) => {
                                setPage(page);
                                setSearchParams({ page: page.toString() });
                            }}
                        />
                    </Flex>
                )}
            </Card>
        </Space>
    );
}

export default Callbacks;
